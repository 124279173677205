<template>
  <div>
    <v-card class="pa-3">
      <v-row align="center" no-gutters>
        <v-col cols="12">
          <span class="body-2">Take a clear picture of the remittance receipt and upload it by clicking the “Send Receipt” button above. If there are any discrepancies to the amount posted, do not hesitate to send us an e-mail or text, or give us a call during working hours so that we can immediately verify and reflect the amount that you have deposited.</span>
        </v-col>
        <v-col cols="12" class="mt-2">
          <v-file-input 
            label="Bank/Remittance Receipt" 
            placeholder="Image Here" 
            prepend-inner-icon="mdi-paperclip" 
            v-model="imageFile"
            class="pb-2"
            prepend-icon 
            outlined
            hide-details
            dense
          >
          </v-file-input>
          <v-text-field 
            label="Amount" 
            type="number"
            v-model="amount"
            ref="amountRef"
            :rules="amountRule"
            outlined
            hide-details
            dense
          >
          </v-text-field>
          <v-btn :loading="isUploading" class="primary mt-2" @click="addFunds()" block depressed>Add Funds</v-btn>
        </v-col>
      </v-row>
    </v-card>
    <CentersBanks />
  </div>
</template>

<script>
import io from 'socket.io-client'
import AddFundsMixins from '@/mixins/addFundsMixins'
import CentersBanks from './CentersBanks'

export default {
  name: 'MobileRemittanceBank',
  components: {
    CentersBanks
  },
  mixins: [ AddFundsMixins ],
  data () {
    return {
      socket: io(process.env.VUE_APP_SERVER),
      isFetching: false,
      isUploading: false,
      imageFile: {},
      remittanceCenters: [],
      banks: [],
      amountRule: [
        (v) => v >= 300 || 'Amount should not be less than P300.00.',
      ],
      amount: 300,
      remittancePersonel: ''
    }
  },
}
</script>

<style scoped>

</style>