import InfoService from '@/services/InfoService'

export default {
  methods: {
    getPaymentMethods () {
      this.isFetching = true
      InfoService.getPaymentMethods()
        .then(res => {
          this.remittancePersonel = res.data.personel
          this.remittanceCenters = res.data.remittanceCenters
          this.banks = res.data.banks
        })
        .finally(() => {
          this.isFetching = false
        })
    },
    addFunds () {
      this.isUploading = true
      if (this.imageFile && this.$refs.amountRef.validate()) {
        const formData = new FormData()
        formData.append('receipt', this.imageFile)
        formData.append('user', this.$store.getters['user/user'].user._id)
        formData.append('type', 'Add Funds')
        formData.append('amount', this.amount)

        let load = {
          formData,
          hasRemitted: true
        }

        this.$store.dispatch('transactions/ADD_FUNDS', load)
          .then(() => {
            this.socket.emit('addfunds', {
              type: 'Add Funds',
              count: 1
            })
            this.$swal({
              type: 'success',
              text: 'Successfully sent the receipt. For faster verification, you may text or call us during working hours to have your payment reflected.'
            })
            this.$router.push('/transactions')
            this.imageFile = {}
            this.amount = 300
          })
          .catch(() => {
            this.$swal({
              type: 'error',
              text: 'There is something wrong in the server!'
            })
          })
          .finally(() => {
            this.isUploading = false
          })
      } else {
        const funds = {
          user: this.$store.getters['user/user'].user._id,
          amount: this.amount,
          type: 'Add Funds',
          addition: true,
          calculate: false
        }
        this.$store.dispatch('transactions/ADD_FUNDS', funds)
          .then(res => {
            this.$swal({
              type: 'success',
              text: 'This is noted. This amount will be reflected to your available balance after we verify the payment receipt that you will send.'
            })
          })
          .catch(() => {
            this.$swal({
              type: 'error',
              text: 'There is something wrong in the server!'
            })
          })
          .finally(() => {
            this.isUploading = false
          })
      }
    }
  },
  created () {
    this.getPaymentMethods()
  }
}