<template>
  <div>
    <v-card v-if="$vuetify.breakpoint.mdAndUp" class="pa-8">
      <v-layout row wrap>
        <v-flex xs6 class="pr-3">
          <p>Take a clear picture of the remittance receipt and upload it by clicking the “Send Receipt” button above. If there are any discrepancies to the amount posted, do not hesitate to send us an e-mail or text, or give us a call during working hours so that we can immediately verify and reflect the amount that you have deposited.</p>
        <v-flex xs12>
          <div class="pb-2">
            <span class="subheading font-weight-medium pr-1">Send Receipt</span>
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">mdi-information</v-icon>    
              </template>
              <span>Lorem Ipsum</span>
            </v-tooltip>
          </div>
          <v-file-input 
            label="Bank/Remittance Receipt" 
            placeholder="Image Here" 
            prepend-inner-icon="mdi-paperclip" 
            v-model="imageFile"
            prepend-icon 
            outlined
          >
          </v-file-input>
          <v-text-field 
            label="Amount" 
            type="number"
            v-model="amount"
            ref="amountRef"
            :rules="amountRule"
            outlined
          >
          </v-text-field>
          <v-btn :loading="isUploading" class="primary pt-0" @click="addFunds()" block large depressed>Add Funds</v-btn>
        </v-flex>
        </v-flex>
        <v-flex xs6 class="pl-3">
          <CentersBanks />
        </v-flex>
      </v-layout>
    </v-card>
    <MobileRemittanceView v-if="!$vuetify.breakpoint.mdAndUp" />
  </div>
</template>

<script>
import InfoService from '@/services/InfoService'
import io from 'socket.io-client'
import MobileRemittanceView from './MobileRemittanceBank'
import CentersBanks from './CentersBanks'
import AddFundsMixins from '@/mixins/addFundsMixins'

export default {
  name: 'BankRemittance',
  components: {
    MobileRemittanceView,
    CentersBanks
  },
  mixins: [ AddFundsMixins ],
  data () {
    return {
      socket: io(process.env.VUE_APP_SERVER),
      isFetching: false,
      isUploading: false,
      imageFile: {},
      remittanceCenters: [],
      banks: [],
      amountRule: [
        (v) => v >= 300 || 'Amount should not be less than P300.00.',
      ],
      amount: 300,
      remittancePersonel: ''
    }
  }
}
</script>

<style scoped>

</style>