<template>
  <v-layout row wrap class="py-5 px-2">
    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
      <v-flex xs6 class="pt-3">
        <v-text-field outlined label="Amount" v-model="amount" type="number"></v-text-field>
        <v-layout row wrap class="px-3">
          <v-flex xs8 class="pb-2">
            <span class="subheading grey--text">Funds</span>
          </v-flex>
          <v-flex xs4 class="text-right pb-2">
            <span class="subheading primary--text font-weight-medium">{{ parseFloat(amount) | phCurrency }}</span>
          </v-flex>
          <v-flex xs8 class="pb-2">
            <span class="subheading grey--text">Payment Fee</span>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon small class="pl-1" v-on="on">help</v-icon>
              </template>
              <span>Calculated as 3.5% of the deposit amount.</span>
            </v-tooltip>
          </v-flex>
          <v-flex xs4 class="text-right pb-2">
            <span class="subheading primary--text font-weight-medium">{{ parseFloat(amount * .035) | phCurrency }}</span>
          </v-flex>
        </v-layout>
        <v-divider></v-divider>
        <v-layout row wrap class="pt-2 px-3">
          <v-flex xs8>
            <span class="subheading grey--text">Total</span>
          </v-flex>
          <v-flex xs4 class="text-right">
            <span class="subheading primary--text font-weight-medium">{{ parseFloat(parseFloat(amount) + parseFloat(paymentFee)) | phCurrency }}</span>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs6 class="pl-4">
        <v-card class="pa-3">
          <v-text-field outlined label="Card Number"></v-text-field>
          <v-layout row wrap class="px-2">
            <v-flex xs4 class="px-1">
              <v-text-field outlined label="Month"></v-text-field>
            </v-flex>
            <v-flex xs4 class="px-1">
              <v-text-field outlined label="Year"></v-text-field>
            </v-flex>
            <v-flex xs4 class="px-1">
              <v-text-field outlined label="CVC">
                <template v-slot:append-outer>
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on">help</v-icon>
                    </template>
                    <span>Lorem Ipsum Dolor</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-flex>
          </v-layout>
          <v-btn block large class="primary">Add Funds</v-btn>
        </v-card>
      </v-flex>
  </v-layout>
</template>

<script>
import AccountingMixins from '@/mixins/accountingMixins'

export default {
  name: 'Paymongo',
  mixins: [ AccountingMixins ],
  data () {
    return {
      amount: 0,
      paymentFee: 0,
      total: 0
    }
  },
  methods: {
    paymentFeeTotal () {
      this.paymentFee = parseFloat(this.amount * .035)
      this.total = parseFloat(parseFloat(this.amount) + parseFloat(this.paymentFee))
    }
  }
}
</script>

<style scoped>
  .borderRadius {
    border-radius: 3%;
  }
</style>