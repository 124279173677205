<template>
  <div>
  <div v-if="!isFetching">
    <v-row no-gutters>
      <v-col :class="$vuetify.breakpoint.mdAndUp ? 'pt-0' : 'pt-2'">
        <v-card class="pa-3">
          <span class="body-1 font-weight-medium">Partner Banks</span>
          <v-row v-for="bank in banks" :key="bank.name" class="pt-2" justify="center" no-gutters>
            <v-col cols="4" class="pr-2 pt-1">
              <v-card>
                <v-img :src="bank.imageUrl"></v-img>
              </v-card>
            </v-col>
            <v-col cols="8" class="pa-0">
              <v-col cols="9" class="pa-0">
                <span class="font-weight-medium body-2">{{ bank.name }}</span>
              </v-col>
             <v-row align="center" justify="center" no-gutters>
                <v-col cols="6" class="pl-0 pt-0">
                  <span class="caption grey--text">Account Name</span><br />
                  <span :class="$vuetify.breakpoint.mdAndUp ? 'body-1' : 'caption'">{{ bank.accountName }}</span>
                </v-col>
                <v-col cols="6" class="pl-0 pt-0">
                  <span class="caption grey--text">Account Number</span><br />
                  <span :class="$vuetify.breakpoint.mdAndUp ? 'body-1' : 'caption'">{{ bank.accountNumber }}</span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col class="pt-2">
      <v-card class="pa-3">
        <span class="body-1 font-weight-medium">Remittance Centers</span>
          <v-col v-for="center in remittanceCenters" :key="center.name" cols="4" class="pl-0">
            <v-row justify="center" no-gutters>
              <v-card>
                <v-img :src="center.imageUrl"></v-img>
              </v-card>
              <span class="body-2">{{ center.name }}</span>
            </v-row>
          </v-col>
        <v-row justify="center" no-gutters>
          <v-col cols="6" class="text-center">
            <span class="caption grey--text">Name</span>
          </v-col>
          <v-col cols="6" class="text-center">
            <span class="caption grey--text">Contact Number</span>
          </v-col>
          <v-col cols="6" class="text-center">
            <span :class="$vuetify.breakpoint.mdAndUp ? 'body-1' : 'caption'">{{ remittancePersonel.name }}</span>
          </v-col>
          <v-col cols="6" class="text-center">
            <span :class="$vuetify.breakpoint.mdAndUp ? 'body-1' : 'caption'">{{ remittancePersonel.contactNum }}</span>
          </v-col>
        </v-row>
      </v-card>
      </v-col>
    </v-row>
  </div>
  <div v-else class="pt-2">
    <v-card v-for="i in 2" :key="i" class="mt-2 dropShadow pa-3">
      <content-placeholders :rounded="true">
        <content-placeholders-text :lines="4"/>
      </content-placeholders>
    </v-card>
  </div>
  </div>
</template>

<script>
import io from 'socket.io-client'
import AddFundsMixins from '@/mixins/addFundsMixins'

export default {
  name: 'CentersBanks',
  mixins: [ AddFundsMixins ],
  data () {
    return {
      socket: io(process.env.VUE_APP_SERVER),
      isFetching: false,
      isUploading: false,
      imageFile: {},
      remittanceCenters: [],
      banks: [],
      amountRule: [
        (v) => v >= 300 || 'Amount should not be less than P300.00.',
      ],
      amount: 300,
      remittancePersonel: ''
    }
  }
}
</script>

<style scoped>

</style>