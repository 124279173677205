<template>
  <v-row justify="center" class="py-5 px-2">
    <v-sheet>
      <v-row justify="center" class="py-5">
        <v-col cols="12" class="text-center">
          <img class="mx-2" width="20%" src="https://res.cloudinary.com/starupgeek-law/image/upload/v1591778757/assets/y2fhjgu3baah12px54yg.png"/>
        </v-col>
        <v-col cols="12" class="text-center">
          <span class="title font-weight-medium grey--text">
            Coming Soon
          </span>
        </v-col>
      </v-row>
    </v-sheet>
  </v-row>
</template>

<script>
export default {
  name: 'PayPalIndex'
}
</script>

<style scoped>

</style>