<template>
    <v-col cols="12" offset-md="1" md="10" offset-lg="2" lg="8" offset-xl="3" xl="6" class="py-5 px-3">
      <!-- <v-row class="text-center" no-gutters>
        <v-col cols="4" class="px-1">
          <v-btn large block outlined color="primary" @click="isRemittance = true, isPaymongo = false, isPaypal = false">
            Remittance/Bank Deposit
          </v-btn>
        </v-col>
        <v-col cols="4" class="px-1">
          <v-btn large block outlined color="success"  @click="isPaymongo = true, isRemittance = false, isPaypal = false">
            <v-img class="ma-13" width="1px" src="https://res.cloudinary.com/starupgeek-law/image/upload/v1591838933/assets/bwlf5v54tw6fldwvbqcc.png"></v-img>
          </v-btn>
        </v-col>
        <v-col cols="4" class="px-1">
          <v-btn large block outlined color="blue" @click="isPaypal = true, isRemittance = false, isPaymongo = false">
            <v-img class="ma-16" width="1px" src="https://res.cloudinary.com/starupgeek-law/image/upload/v1591836988/assets/z0qzdjtikglxdazcl1d0.png"></v-img>
          </v-btn>
        </v-col>
      </v-row> -->
      <RemittanceBank v-if="isRemittance"/>
      <Paymongo v-if="isPaymongo"/>
      <PayPal v-if="isPaypal"/>
    </v-col>
</template>

<script>
import RemittanceBank from '@/components/AddFundsComponents/RemittanceBank'
import Paymongo from '@/components/AddFundsComponents/Paymongo'
import PayPal from '@/components/AddFundsComponents/PayPal'

export default {
  name: 'AddFundsPay',
  components: {
    RemittanceBank,
    Paymongo,
    PayPal
  },
  data () {
    return {
      isRemittance: false,
      isPaymongo: false,
      isPaypal: false,
      radioGroup: 1
    }
  },
  created () {
    this.isRemittance = true
  }
}
</script>

<style scoped>

</style>